export default function setupSearchResponsive() {
  var filterModal = document.getElementById('filter-modal');
  var filterBtn = document.getElementById('filter-btn');
  var filterSpans = document.getElementsByClassName('filter-close');

  if (filterBtn) {
    filterBtn.onclick = function(event) {
      event.preventDefault();
      filterModal.style.display = 'block';
    };
  }

  Array.from(filterSpans).forEach(span => {
    span.onclick = function() {
      filterModal.style.display = 'none';
    };
  });

  window.onclick = function(event) {
    if (event.target == filterModal) {
      filterModal.style.display = 'none';
    }
  };
}

document.addEventListener('DOMContentLoaded', function() {
  const clearButtons = document.querySelectorAll('.search__clear');
  const choiceElements = document.querySelectorAll('.js-choice');
  const searchElements = document.querySelectorAll('.js-search__input');
  const datePickerElements = document.querySelectorAll('.js-date-picker-input');
  const filterBtn = document.getElementById('filter-btn');
  const filterBtnSVGPath = filterBtn.querySelector('svg path');

  function checkSelections() {
    let isSelected = false;

    choiceElements.forEach(element => {
      if (element.value) {
        isSelected = true;
      }
    });

    datePickerElements.forEach(element => {
      if (element.value) {
        isSelected = true;
      }
    });

    searchElements.forEach(element => {
      if (element.value) {
        isSelected = true;
      }
    });

    // Update the style of the filter button based on selection
    if (isSelected) {
      filterBtn.style.color = '#B43EC1';  
      filterBtnSVGPath.style.stroke = '#B43EC1'; 
    } else {
      filterBtn.style.color = '';  
      filterBtnSVGPath.style.stroke = ''; 
    }

    // Apply style changes to clear buttons
    clearButtons.forEach(button => {
      if (isSelected) {
        button.style.border = '1px solid #001ED7';
        button.style.background = '#FFF';
        button.style.boxShadow = '0px 2px 3px 0px rgba(0, 30, 215, 0.30)';
        button.style.color = '#001ED7';
      } else {
        button.style.border = '1px solid #D8D8D8';
        button.style.background = '#D8D8D8';
        button.style.boxShadow = 'none';
      }
    });
  }

  choiceElements.forEach(element => {
    element.addEventListener('change', checkSelections);
  });

  datePickerElements.forEach(element => {
    element.addEventListener('input', checkSelections);
  });

  searchElements.forEach(element => {
    element.addEventListener('input', checkSelections);
  });
  
  clearButtons.forEach(button => {
    button.addEventListener('click', function() {
      choiceElements.forEach(element => {
        element.value = '';
      });
      datePickerElements.forEach(element => {
        element.value = '';
      });
      checkSelections();
    });
  });

  checkSelections();
});
