import Choices from 'choices.js';

const choicesInstances = new Map();

export default function() {
  // Clear any existing instances first
  destroyChoicesInstances();
  
  // Initialize new instances
  const elements = document.querySelectorAll('.js-choice');
  elements.forEach(setup);
  
  return { 
    clearAll: clearChoicesInstances,
    destroyAll: destroyChoicesInstances,
    rebuild: rebuildChoices,
    instances: choicesInstances,
  };
}

function clearChoicesInstances() {
  try {
    choicesInstances.forEach((instance) => {
      if (instance && typeof instance.clearStore === 'function') {
        instance.clearStore();
      }
    });
  } catch (e) {
    console.error('Error clearing choices:', e);
  }
}

function destroyChoicesInstances() {
  try {
    choicesInstances.forEach((instance) => {
      if (instance && typeof instance.destroy === 'function') {
        instance.destroy();
      }
    });
    choicesInstances.clear();
  } catch (e) {
    console.error('Error destroying choices:', e);
  }
}

function rebuildChoices() {
  destroyChoicesInstances();
  document.querySelectorAll('.js-choice').forEach(setup);
}

function setup(element) {
  try {
    if (!element) return;

    // Destroy existing instance if any
    const existingInstance = choicesInstances.get(element);
    if (existingInstance) {
      existingInstance.destroy();
    }

    // Create new instance
    const instance = new Choices(element, {
      placeholderValue: '',
      removeItemButton: true,
      allowHTML: true,
    });

    // Store instance
    choicesInstances.set(element, instance);
    
    if (element.value) {
      instance.containerInner.element.classList.add('choices__inner_is-selected');
    }

  } catch (e) {
    console.error('Error setting up choice:', e);
  }
}

// function syncSelections(sourceElement, selectedValue) {
//   const counterpartSelector = sourceElement.classList.contains('js-choice-mobile')
//     ? '.js-choice-desktop'
//     : '.js-choice-mobile';
//   const counterpartElement = document.querySelector(`${counterpartSelector}[name="${sourceElement.name}"]`);
  
//   if (counterpartElement) {
//     const counterpartInstance = choicesInstances.get(counterpartElement);
//     if (counterpartInstance && counterpartElement.value !== selectedValue) {
//       counterpartInstance.setChoiceByValue(selectedValue);
//     }
//   }
// }
