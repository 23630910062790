let timeout;

export default function () {
  // const stickyBtn = document.querySelector('#long-article .donate-btn');
  // const fixedBtn = document.querySelector('#long-article .long-content__latest .donate-btn');
  // const initialFixedBtnPosition = fixedBtn.getBoundingClientRect().left;

  setupPullovers();
  document.addEventListener('scroll', handleScroll, { passive: true });

  function handleScroll() {
    easeOutTableOfContents();
    // handleDonateBtnVisibility();
  }

  //   function toggleButtonsVisibility(showStickyBtn) {
  //     stickyBtn.style.opacity = showStickyBtn ? 100 : 0;
  //     fixedBtn.style.opacity = showStickyBtn ? 0 : 100;
  //   }

  //   function adjustFixedButtonPosition(clientWidth) {
  //     if (clientWidth > 1546) {
  //       const fixedBtnLeft = initialFixedBtnPosition;
  //       const stickyBtnLeft = stickyBtn.getBoundingClientRect().left;
  //       const delta = Math.abs(fixedBtnLeft - stickyBtnLeft);
  //       const newPosition = fixedBtnLeft + delta - (clientWidth - 1546) / 2;
  //       fixedBtn.style.setProperty('left', `${newPosition}px`, 'important');
  //     } else if (clientWidth < 1546 && clientWidth > 1279) {
  //       fixedBtn.style.setProperty('left', '61.5%', 'important');
  //     } else if (clientWidth < 1279 && clientWidth > 768) {
  //       fixedBtn.style.setProperty('left', '40px', 'important');
  //     } else if (clientWidth < 768) {
  //       fixedBtn.style.setProperty('left', '30px', 'important');
  //     }
  //   }
  // }

  // function isLower(elem1, elem2) {
  //   const clientHeight =
  //     window.innerHeight || document.documentElement.clientHeight;
  //   const elem1DeltaTop = clientHeight - elem1.getBoundingClientRect().top;
  //   const elem2DeltaTop = clientHeight - elem2.getBoundingClientRect().top;
  //   return elem1DeltaTop < elem2DeltaTop;
  // }

  function easeOutTableOfContents() {
    restartTocTimer();

    const notes = document.querySelector('.js-toc-stop');
    const container = document.querySelector('.js-sticky-toc-container');
    const notesListTop =
      notes.getBoundingClientRect().top -
      150 -
      document.querySelector('.js-toc-content').offsetHeight;

    container.style.setProperty(
      '--toc-sticky-top',
      `${Math.min(92, notesListTop)}px`
    );
  }

  function setupPullovers() {
    calculatePullOverSideLength();
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.addEventListener('change', calculatePullOverSideLength);
  }

  function calculatePullOverSideLength() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const iOSVersionMatch = iOS ? navigator.userAgent.match(/ OS (\d+)_/) : null;
    const iOSVersion = iOS ? (iOSVersionMatch && iOSVersionMatch[1] ? parseInt(iOSVersionMatch[1]) : 0) : 0;
    const isOldiOS = iOS && iOSVersion < 18;
    
    const startLength = 100;
    const increment = isSafari ? 5 : 10;

    document.querySelectorAll('.long-content__pullover-shadow').forEach((pullover) => {
        const parent = pullover.querySelector('.long-content__pullover');
        const child = pullover.querySelector('.js-text');
        
        if (!parent || !child) return;

        let sideLength = startLength;
        pullover.style.setProperty('--side-length', sideLength);
        
        if (isSafari && !isOldiOS) {
          const style = document.createElement('style');
          style.innerHTML = `
            span.long-content__pullover::before {
              shape-outside: polygon(100% 0, 2% 0, 50% 6%, 76.6% 17.3%, 94% 32.6%, 100% 50%, 94% 65.6%, 76.6% 82.7%, 50% 94%, 2% 100%, 100% 100%);
            }
            .long-content__pullover i {
              shape-outside: polygon(0 0, 98% 0, 50% 6%, 23.4% 17.3%, 6% 32.6%, 0 50%, 6% 65.6%, 23.4% 82.7%, 50% 94%, 98% 100%, 0 100%);
            }
          `;
          document.head.appendChild(style);
        } else if (isOldiOS) {
          // Alternative approach for older iOS versions
          // Use basic styling without deprecated shape-outside polygons
          const style = document.createElement('style');
          style.innerHTML = `
            span.long-content__pullover::before {
              border-radius: 50%;
              clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
              -webkit-clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
            }
            .long-content__pullover i {
              border-radius: 50%;
              clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
              -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
            }
          `;
          document.head.appendChild(style);
        }

        requestAnimationFrame(() => {
            while (child.offsetHeight > parent.offsetHeight) {
                sideLength += increment;
                pullover.style.setProperty('--side-length', sideLength);
            }
        });
    });
}

  function hideToc() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      document
        .querySelector('.js-toc-ease-out')
        .classList.add('long-content__table-of-contents_is-hidden');
      document
        .querySelectorAll('.js-share-checkbox')
        .forEach((share) => (share.checked = false));
    }, 4000);
  }

  function restartTocTimer() {
    document
      .querySelector('.js-toc-ease-out')
      .classList.remove('long-content__table-of-contents_is-hidden');
    hideToc();
  }
}
