import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import setupChoice from './choice';

// Custom Arabic localization adjustments
Arabic.months.longhand = [
  'كانون الثاني',
  'شباط',
  'آذار',
  'نيسان',
  'أيّار',
  'حزيران',
  'تمّوز',
  'آب',
  'أيلول',
  'تشرين الأول',
  'تشرين الثاني',
  'كانون الأول',
];
Arabic.weekdays.shorthand = Arabic.weekdays.longhand;
Arabic.firstDayOfWeek = 1;

export default function setupSearchPage() {
  const choiceSetup = setupChoice();
  // Search form handling
  const searchForms = document.querySelectorAll('form[action="/search"]');
  searchForms.forEach((form) => {
    form.addEventListener('submit', handleSearchSubmit);
  });

  // Clear filters functionality
  const clearFilterButtons = document.querySelectorAll('.js-clear-filters');
  clearFilterButtons.forEach(button => {
    button.addEventListener('click', handleClearFilters);
  });

  ///
  /// Search tab functionality
  ///

  const tabContainer = document.querySelector('.search-tabs');
  if (tabContainer) {
    const contentTab = tabContainer.querySelector('[data-tab="content"]');
    const authorTab = tabContainer.querySelector('[data-tab="author"]');
    const contentPanel = tabContainer.querySelector('.content-panel');
    const authorsPanel = tabContainer.querySelector('.authors-panel');

    if (contentTab && authorTab && contentPanel && authorsPanel) {

      contentTab.addEventListener('click', () => switchTab('content'));
      authorTab.addEventListener('click', () => switchTab('author'));

      if (window.location.pathname.includes('/author')) {
        switchTab('author');
      }
    }
  }

  function switchTab(tabName) {
    let isAuthorTabActive = false;
    const contentTab = tabContainer.querySelector('[data-tab="content"]');
    const authorTab = tabContainer.querySelector('[data-tab="author"]');
    const contentPanel = tabContainer.querySelector('.content-panel');
    const authorsPanel = tabContainer.querySelector('.authors-panel');

    isAuthorTabActive = tabName === 'author';

    contentTab.classList.toggle('is-active', !isAuthorTabActive);
    authorTab.classList.toggle('is-active', isAuthorTabActive);

    contentPanel.style.display = isAuthorTabActive ? 'none' : 'block';
    authorsPanel.style.display = isAuthorTabActive ? 'block' : 'none';

    const currentURL = new URL(window.location.href);
    const path = isAuthorTabActive ? '/search/author' : '/search';
    history.replaceState(null, '', `${path}?${currentURL.searchParams}`);
  }

  ///
  // Sorting functionality
  //

  //Sorting functionality
  const sortOptions = document.querySelectorAll('.sort-option');
  const sortButton = document.querySelector('.sort-button .sort-title');
  updateSortingUI();
  function updateSortingUI() {
    // Check if URL has sort=newest parameter
    const currentURL = new URL(window.location.href);
    const isRelevanceSort = currentURL.searchParams.get('sort') === 'relevance';
    
    if (!isRelevanceSort && sortButton) {
      sortButton.textContent = 'فرز حسب الأحدث';
    } else if (sortButton) {
      sortButton.textContent = 'فرز حسب الصلة';
    }
  }

  sortOptions.forEach((option) => {
    option.addEventListener('click', function (e) {
      e.preventDefault();
      const sortType = this.getAttribute('data-sort');

      // Get current URL and params
      const currentURL = new URL(window.location.href);
      const searchParams = currentURL.searchParams;

      // Always update sort parameter regardless of search presence
      if (sortType === 'relevance') {
        searchParams.set('sort', 'relevance');
      } else if (sortType === 'newest') {
        searchParams.delete('sort'); // Default sorting
      }

      // Update UI before reload
      updateSortingUI(true, sortType === 'newest');

      // Preserve the current tab state in URL
      const path = window.location.pathname.includes('/author') ? 
        '/search/author' : 
        '/search';

      // Reload with new params
      window.location.href = `${path}?${searchParams.toString()}`;
    });
  });

  ///
  /// URL handling for author and content tabs
  ///

  /// url handle to remove blank params

  function handleSearchSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const searchParams = new URLSearchParams();

    for (let [key, value] of formData.entries()) {
      if (value.trim() !== '') {
        searchParams.append(key, value);
      }
    }

    const url = `${form.action}?${searchParams.toString()}`;
    window.location.href = url;
  }

  ///
  /// LOAD MORE BUTTON
  ///

  let postsCurrentPage = 1;
  // let authorsCurrentPage = 1;
  let loading = false;
  // const authorsResults = document.querySelector('.details-content-authors');

  // Add event listener for the load more buttons
  const loadMoreContainer = document.querySelector('.load-more-container');
  if (loadMoreContainer) {
    loadMoreContainer.addEventListener('click', function(e) {
      if (e.target && e.target.classList.contains('load-more-posts-btn')) {
        if (loading) return;
        loadMorePosts(e.target);
      }
    });
  }

  // Load more posts with button
  function loadMorePosts(button) {
    loading = true;
    button.textContent = 'جاري التحميل...';
    button.disabled = true;
    
    postsCurrentPage++;
    
    const url = new URL(window.location.href);
    url.searchParams.set('page', postsCurrentPage);

    fetch(url, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Create a temporary container to hold the new content
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = data.posts;
        
        // Find all the post elements (not the load-more button)
        const newPosts = tempContainer.querySelectorAll('.search_widget');
        
        // Get the current load more container
        const loadMoreContainer = button.closest('.load-more-container');
        
        // Insert only the posts before the current load more button
        newPosts.forEach(post => {
          loadMoreContainer.insertAdjacentElement('beforebegin', post);
        });
        
        // Check if the response includes a new load more button
        const newLoadMoreBtn = tempContainer.querySelector('.load-more-container');
        
        // If we're at the last page or no new button was returned, remove the current button
        if (!newLoadMoreBtn || postsCurrentPage >= parseInt(button.getAttribute('data-total-pages'))) {
          loadMoreContainer.remove();
        } else {
          // Otherwise, update the current button
          button.textContent = 'تحميل المزيد من المواد';
          button.disabled = false;
          button.setAttribute('data-current-page', postsCurrentPage);
        }
        
        loading = false;
      })
      .catch((error) => {
        console.error('Error loading more posts:', error);
        button.textContent = 'حدث خطأ، حاول مرة أخرى';
        button.disabled = false;
        loading = false;
      });
  }

  // Load more authors with button
  // function loadMoreAuthors(button) {
  //   loading = true;
  //   button.textContent = 'جاري التحميل...';
  //   button.disabled = true;
    
  //   authorsCurrentPage++;
    
  //   const url = new URL(window.location.href);
  //   url.searchParams.set('page', authorsCurrentPage);
  //   url.searchParams.set('search_author', 'true');

  //   fetch(url, {
  //     headers: {
  //       Accept: 'application/json',
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       authorsResults.insertAdjacentHTML('beforeend', data.posts);

        
  //       loading = false;
  //     })
  //     .catch((error) => {
  //       console.error('Error loading more authors:', error);
  //       button.textContent = 'حدث خطأ، حاول مرة أخرى';
  //       button.disabled = false;
  //       loading = false;
  //     });
  // }

  ///
  /// FILTERS
  ///

  function handleClearFilters() {
    // TODO : The Choice.js library does not seem to allow us to clear the selected choice
    // Solution was to destroy the choices and Reset all search forms
    choiceSetup.destroyAll(); 
    choiceSetup.rebuild();    
  }
}
