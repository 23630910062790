import flatpickr from 'flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

// Custom Arabic localization adjustments
Arabic.months.longhand = [
  'كانون الثاني', 'شباط', 'آذار', 'نيسان', 'أيّار', 'حزيران',
  'تمّوز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول',
];
Arabic.weekdays.shorthand = Arabic.weekdays.longhand;
Arabic.firstDayOfWeek = 1;

export default function () {
  const dateInputs = document.querySelectorAll('.date-picker');

  if (dateInputs) {
    dateInputs.forEach((input) => {
      // Check if the input already has a value (e.g., from a previous form submission)
      if (input.value.trim() !== '') {
        input.classList.add('selected');
      }
    });

    flatpickr(dateInputs, {
      locale: Arabic,
      mode: 'range',
      dateFormat: 'd-m-Y',
      maxDate: new Date(),
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length === 2) {
          const startDate = selectedDates[0]
            .toLocaleDateString('en-GB')
            .split('/')
            .reverse()
            .join('-');
          const endDate = selectedDates[1]
            .toLocaleDateString('en-GB')
            .split('/')
            .reverse()
            .join('-');
          instance.input.value = startDate + ' - ' + endDate;

          // Add 'selected' class to the input after date selection
          instance.input.classList.add('selected');
        }
      },
    });
  }

  // function checkClearVisibility(input, clearButton) {
  //   if (input.value.trim() === '') {
  //     clearButton.classList.remove('search__filter-clear_is-visible');
  //   } else {
  //     clearButton.classList.add('search__filter-clear_is-visible');
  //   }
  // }

  // function syncDates(source, target) {
  //   if (target.value !== source.value) {
  //     target.value = source.value;
  //   }
  // }

  // // Initialize flatpickr for both inputs
  // let instanceDesktop = flatpickr(inputDesktop, {
  //   locale: Arabic,
  //   mode: 'range',
  //   dateFormat: 'd-m-Y',
  //   maxDate: new Date(),
  //   onChange: function () {
  //     checkClearVisibility(inputDesktop, clearDesktop);
  //     syncDates(inputDesktop, inputMobile); // Sync desktop to mobile
  //   },
  // });

  // if (inputDesktop) {
  //   let instanceMobile = flatpickr(inputMobile, {
  //     locale: Arabic,
  //     mode: 'range',
  //     dateFormat: 'd-m-Y',
  //     maxDate: new Date(),
  //     onChange: function () {
  //       checkClearVisibility(inputMobile, clearMobile);
  //       syncDates(inputMobile, inputDesktop); // Sync mobile to desktop
  //     },
  //   });
  // }


  // // Check initial visibility of clear buttons
  // checkClearVisibility(inputDesktop, clearDesktop);
  // checkClearVisibility(inputMobile, clearMobile);

  // // Add event listeners to clear buttons
  // clearDesktop.addEventListener('click', function () {
  //   instanceDesktop.clear();
  //   checkClearVisibility(inputDesktop, clearDesktop);
  // });
  // if (clearMobile) {
  //   clearMobile.addEventListener('click', function () {
  //     instanceMobile.clear();
  //     checkClearVisibility(inputMobile, clearMobile);
  //   });
  // }
}
